<template>
	<div class="form-container">
		<div>
			<h4>Store front visibility (Website)</h4>
			<hr class="my-5">
		</div>
		<section class="flash-sale-banner-container">
			<form class="mt-4 mb-3" @submit.prevent="handleSubmit">
				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-homepage-desktop.svg"
					mobile-img="/assets/images/flash-sale-homepage-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Homepage banner
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
					</template>
					<template #detail>
						Flash sale homepage banner will be displayed in flash sale section. Please avoid to add information on the bottom of banner to keep spacing for time counter and call to action.
					</template>
					<BaseMediaSelectorBox
						ref="homepageDesktopBanner"
						v-model="formData.homepageDesktopBanner"
						title="Desktop banner*"
						no-image-text="No banner"
						show-helper
						width="552"
						height="892"
						class="mt-4"
						required
					/>
					<BaseMediaSelectorBox
						ref="homepageMobileBanner"
						v-model="formData.homepageMobileBanner"
						title="Mobile banner*"
						no-image-text="No banner"
						show-helper
						width="764"
						height="280"
						class="mt-2"
						required
					/>
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Time counter font color
						</p>
						<BaseButtonGroup
							ref="counterFontColor"
							v-model="formData.counterFontColor"
							:list="fontColorOptions"
							data-test-id="counter-font-color"
						/>
					</div>
				</BannerContentWrapper>

				<BannerContentWrapper
					ref="content"
					:has-banner-link="false"
					desktop-img="/assets/images/flash-sale-list-desktop.svg"
					mobile-img="/assets/images/flash-sale-list-mobile.svg"
					@update:bannerLink="bannerLink = $event"
				>
					<template #title>
						Flash sale banner
					</template>
					<template #description>
						<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
					</template>
					<template #detail>
						Flash sale banner will be displayed on the top of flash sale page.
					</template>
					<BaseMediaSelectorBox
						v-model="formData.flashSaleDesktopBanner"
						title="Desktop banner"
						no-image-text="No banner"
						show-helper
						width="2,352"
						height="640"
						class="mt-4"
					/>
					<BaseMediaSelectorBox
						v-model="formData.flashSaleMobileBanner"
						title="Mobile banner"
						no-image-text="No banner"
						show-helper
						width="656"
						height="240"
						class="mt-2"
					/>
				</BannerContentWrapper>

				<FlashSaleContent
					:description-top.sync="formData.descriptionTop"
					:description-bottom.sync="formData.descriptionBottom"
				/>
			</form>
		</section>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import FlashSaleContent from '@/components/FlashSaleContent.vue';
import { COUNTER_FONT_COLOR } from '../enums/flashSales';

export default {
	name: 'BannerHeroForm',
	components: {
		BannerContentWrapper,
		BaseMediaSelectorBox,
		FlashSaleContent,
	},
	validations: {
		formData: {
			homepageDesktopBanner: {
				required,
			},
			homepageMobileBanner: {
				required,
			},
			counterFontColor: {
				required,
			},
		},
	},
	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		descriptionBottom: {
			type: String,
			default: null,
		},
		descriptionTop: {
			type: String,
			default: null,
		},
	},
	data() {
		const {
			counterFontColor = null,
			homepageDesktopBanner = null,
			homepageMobileBanner = null,
			flashSaleDesktopBanner = null,
			flashSaleMobileBanner = null,
			descriptionBottom = null,
			descriptionTop = null,
		} = this.defaultData || {};

		return {
			formData: {
				counterFontColor,
				homepageDesktopBanner,
				homepageMobileBanner,
				flashSaleDesktopBanner,
				flashSaleMobileBanner,
				descriptionBottom,
				descriptionTop,
			},
		};
	},
	computed: {
		fontColorOptions() {
			return COUNTER_FONT_COLOR.map((color) => ({
				label: color.name,
				value: color.value,
			}));
		},
	},
	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}

	.remove-banners {
		font-size: rem(14);
		color: $color-orange;
		align-items: center;
		cursor: pointer;
	}
</style>
